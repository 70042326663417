import axios from "axios";
import { addPlaceSuggestionToCurationUrl, getCreateCurationUrl, getCurationMembersUrl, getCurationPlaceSuggestionsUrl, getCurationsUrl, getDeleteCurationUrl, getSearchCurationsUrl, getUpdateCurationCoverPhotoUrl, getUpdateCurationUrl, getUserCurationsUrl, getInvitationDetailsUrl } from "./ServiceUrls";
import { createClient } from "@supabase/supabase-js";

const supabase = createClient('https://oesctluxvegwtcqckcdg.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9lc2N0bHV4dmVnd3RjcWNrY2RnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc5Njc3NjEsImV4cCI6MjAxMzU0Mzc2MX0.9gTKhSVxZReF3sEi5N9mpNSTEjiOEkwA8TDdijsDkZw')

export default class CurationRepository {
    async getCurations(curationId) {
        const session = await supabase.auth.getSession()
        const response = await axios.get(
            getCurationsUrl(curationId),
            {
                headers: {
                    'Authorization': `Bearer ${session.data.session.access_token}`
                }
            }
        );
        return response.data
    }

    async deleteCuration(curationId) {
        const session = await supabase.auth.getSession()
        const response = await axios.delete(
            getDeleteCurationUrl(curationId),
            {
                headers: {
                    'Authorization': `Bearer ${session.data.session.access_token}`
                }
            }
        );
    }

    async getCurationMembers(curationId) {
        const session = await supabase.auth.getSession()
        const response = await axios.get(
            getCurationMembersUrl(curationId),
            {
                headers: {
                    'Authorization': `Bearer ${session.data.session.access_token}`
                }
            }
        );
        return response.data
    }

    async getCurationPlaceSuggestions(curationId) {
        const session = await supabase.auth.getSession()
        const response = await axios.get(
            getCurationPlaceSuggestionsUrl(curationId),
            {
                headers: {
                    'Authorization': `Bearer ${session.data.session.access_token}`
                }
            }
        );
        return response.data
    }

    async addPlaceSuggestionToCuration(suggestionId, curationId) {
        const session = await supabase.auth.getSession()
        const response = await axios.post(
            addPlaceSuggestionToCurationUrl(suggestionId, curationId),
            {},
            {
                headers: {
                    'Authorization': `Bearer ${session.data.session.access_token}`
                }
            }
        );
        return response.data
    }

    async getUserCurations() {
        const session = await supabase.auth.getSession()
        const response = await axios.get(
            getUserCurationsUrl(),
            {
                headers: {
                    'Authorization': `Bearer ${session.data.session.access_token}`
                }
            }
        );
        return response.data
    }

    async getSearchCurations(input) {
        const session = await supabase.auth.getSession()
        const response = await axios.get(
            getSearchCurationsUrl(input),
            {
                headers: {
                    'Authorization': `Bearer ${session.data.session.access_token}`
                }
            }
        );
        return response.data
    }

    async createCuration(
        title, description, filters
    ) {
        const session = await supabase.auth.getSession()
        const response = await axios.post(
            getCreateCurationUrl(),
            {
                filters: filters,
                title: title,
                description: description
            },
            {
                headers: {
                    'Authorization': `Bearer ${session.data.session.access_token}`
                }
            }
        );
        return response.data
    }

    async updateCuration(
        curationId, title, description, filters, emoji
    ) {
        const session = await supabase.auth.getSession()
        const response = await axios.post(
            getUpdateCurationUrl(curationId),
            {
                filters: filters,
                title: title,
                description: description,
                emoji: emoji
            },
            {
                headers: {
                    'Authorization': `Bearer ${session.data.session.access_token}`
                }
            }
        );
        return response.data
    }

    async uploadCoverPicture(curationId, picture) {
        const session = await supabase.auth.getSession()
        const formData = new FormData()
        formData.append(`image`, picture)
        const response = await axios.post(
            getUpdateCurationCoverPhotoUrl(curationId),
            formData,
            {
                headers: {
                    'Authorization': `Bearer ${session.data.session.access_token}`,
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
        return response.data
    }

    async getInvitationDetails(invitationLinkId) {
        const session = await supabase.auth.getSession()
        const response = await axios.get(
            getInvitationDetailsUrl(invitationLinkId),
            {
                headers: {
                    'Authorization': `Bearer ${session.data.session.access_token}`
                }
            }
        );
        return response.data
    }
}
