import axios from "axios";
import { getSaveSupportTicketUrl } from "./ServiceUrls";
import { createClient } from "@supabase/supabase-js";

const supabase = createClient('https://oesctluxvegwtcqckcdg.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9lc2N0bHV4dmVnd3RjcWNrY2RnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc5Njc3NjEsImV4cCI6MjAxMzU0Mzc2MX0.9gTKhSVxZReF3sEi5N9mpNSTEjiOEkwA8TDdijsDkZw')

export default class SupportTicketRepository {
    async saveSupportTicket(ticketContent, contact) {
        const session = await supabase.auth.getSession()
        let headers = {}
        if (session != null && session.data.session != null) {
            headers = {
                'Authorization': `Bearer ${session.data.session.access_token}`
            }
        } else {
            headers = {}
        }
        const response = await axios.post(
            getSaveSupportTicketUrl(),
            {
                content: ticketContent,
                contact: contact
            },
            {
                headers: headers
            }
        );
        return response.data
    }
}
