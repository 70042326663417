import { useState, useEffect } from 'react'
import { createClient } from '@supabase/supabase-js'
import { logEvent } from 'firebase/analytics';
import { AnalyticsEvent } from '../../shared/analytics/AnalyticsEvent';
import CurationRepository from '../../repository/CurationRepository';
import { useNavigate, useParams } from "react-router-dom"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { Helmet } from "react-helmet"
import CoorayAppBar from "../../shared/components/CoorayAppBar"
import { NavigationButtonType } from "../../shared/components/CoorayAppBar"
import FeedbackButton from "../../shared/components/feedback/FeedbackButton"
import UserRepository from '../../repository/UserRepository';
import Lottie from "lottie-react"
import lottieSuccess from '../../shared/lottie/lottie-success.json';

const supabase = createClient('https://oesctluxvegwtcqckcdg.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9lc2N0bHV4dmVnd3RjcWNrY2RnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc5Njc3NjEsImV4cCI6MjAxMzU0Mzc2MX0.9gTKhSVxZReF3sEi5N9mpNSTEjiOEkwA8TDdijsDkZw')

export default function AcceptInvitationPage( { analytics } ) {

  const { invitationLinkId }= useParams()
  const [loadingText, setLoadingText] = useState(null)
  const [isErrorShowing, setIsErrorShowing] = useState(false)
  const [bigErrorText, setBigErrorText] = useState(null)
  const [acceptedInvitationDetails, setAcceptedInvitationDetails] = useState(null)
  const [invitationDetails, setInvitationDetails] = useState(null)
  const navigate = useNavigate()


  useEffect(() => {
  
    const curationRepository = new CurationRepository()
    async function getInvitationDetails() {
        setLoadingText("Loading ...")
        const {
          data: { user },
        } = await supabase.auth.getUser()
        if (user == null) {
          navigate("/login", {replace: true})
        } else {
          try {
            const invitationDetailsResponse = await curationRepository.getInvitationDetails(invitationLinkId)
            console.log(invitationDetailsResponse)
            setInvitationDetails(invitationDetailsResponse)
            setLoadingText(null)
          } catch (e) {
            setLoadingText(null)
            setBigErrorText("Invitation not found!")
          }
          
        }
    }
    getInvitationDetails()
    logEvent(analytics, AnalyticsEvent.ACCEPT_INVITATION_VIEW, {})
  }, [invitationLinkId])


  async function onAcceptInvitationClick() {
    if (invitationDetails) {
      setLoadingText("Accepting ...")
      const userRepository = new UserRepository()
      const response = await userRepository.acceptInvitationLink(invitationDetails.invitationLinkId)
      console.log(`response is ${response}`)
      setAcceptedInvitationDetails(invitationDetails)
      setLoadingText(null)
    }
  }

  async function onCancelClick() {
    navigate("/")
  }

  async function onCloseClick() {
    navigate("/")
  }
  return <div>
      <Helmet><title>Invitation | Roambear</title></Helmet>
        <CoorayAppBar title={ "" } onBackPressed={onCloseClick} navigationType={NavigationButtonType.Close}/>
        { !acceptedInvitationDetails && invitationDetails && 
          <div className='grow bg-base-100 flex flex-col place-content-center overflow-y-hidden'>

            <div className="relative h-60 w-full">
              {/* Cover Image */}
              <LazyLoadImage src={"https://media.karousell.com/media/photos/products/2025/2/4/ikea_kitchen_set_1738713451_e565501d_progressive.jpg"} className="w-full h-48 object-cover rounded-md max-w-2xl absolute top-0 left-1/2 transform -translate-x-1/2"/>

              {/* Avatar */}
              <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2">
                    { !invitationDetails.inviterProfilePicture && <div className="avatar placeholder self-center">
                  <div className="bg-neutral text-xl text-neutral-content rounded-full w-24 border-4 border-base-content">
                      <span>{ invitationDetails.inviterName[0] }</span>
                  </div>
                  </div> 
                  }
                  { invitationDetails.inviterProfilePicture && <div className="avatar self-center">
                    <div className="w-24 border-4 border-base-content rounded-full">
                      <LazyLoadImage src={invitationDetails.inviterProfilePicture} />
                    </div>
                  </div>
                  }
              </div>
            </div>
            <div className="pt-2 text-base text-base-content w-full text-center self-center max-w-lg pt-4"><span className='font-bold'>{invitationDetails.inviterName}</span> invited you to the collection</div>
            <div className="pt-2 text-xl text-base-content w-full text-center self-center max-w-lg pt-4"><span className='font-bold'>{invitationDetails.curationName}</span></div>
            { isErrorShowing && <div className="pt-2 text-sm text-error w-full text-center self-center max-w-lg">Invalid invitation code</div> }
            <div className='self-center pt-8 grid grid-cols-2 gap-2'>
                <button className="btn btn-success normal-case" onClick={() => onAcceptInvitationClick() }>
                    <svg className="w-6 h-6" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                        <path className="stroke-success-content" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 4H6a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.828a2 2 0 0 0-.586-1.414l-1.828-1.828A2 2 0 0 0 16.172 4H15M8 4v4a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V4M8 4h7M7 17v-3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3"/>
                    </svg>
                    Accept
                </button>
                <button className="btn btn-neutral normal-case" onClick={ () => onCancelClick() }>
                    Cancel
                </button>

            </div>
        </div>
        }

        { acceptedInvitationDetails && 
          <div className='grow bg-base-100 flex flex-col items-center place-content-center overflow-y-hidden'>
              <Lottie className="w-36 h-36" animationData={lottieSuccess} loop={true} />
              <div className="text-base text-base-content w-full text-center self-center max-w-lg">Invitation accepted!</div>
              <div className="pt-2 text-xl text-base-content w-full text-center self-center max-w-lg pt-4">Welcome to <span className='font-bold'>{invitationDetails.curationName}</span></div>
              <div className='self-center pt-8'>
                  <button className="btn btn-neutral normal-case" onClick={ () => onCancelClick() }>
                      Back to Home
                  </button>
              </div>
        </div>
        }

        { bigErrorText && 
          <div className='grow bg-base-100 flex flex-col place-content-center overflow-y-hidden'>

            <div className="pt-2 text-base text-base-content w-full text-center self-center max-w-lg pt-4">{ bigErrorText }</div>
            <div className='self-center pt-8'>
                <button className="btn btn-neutral normal-case" onClick={ () => onCancelClick() }>
                    Back to Home
                </button>

            </div>
        </div>
        }

        {loadingText &&
        <div className='w-full h-screen fixed top-0 flex flex-col items-center justify-center backdrop-blur-sm bg-base-100/30'>
            <div className="loading loading-infinity loading-lg"></div>
            <div className='font-normal text-base-content mt-2'>{loadingText}</div>
        </div>
        }
        {
        <FeedbackButton />
        }
    </div>
}