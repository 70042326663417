import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import CurationPage from "./pages/curation/CurationPage";
import AddPlacePage from "./pages/addplace/AddPlacePage";
import AddCurationPage from "./pages/addcuration/AddCurationPage";
import PlaceDetailsPage from "./pages/placedetails/PlaceDetailsPage";
import EditCurationPage from "./pages/editcuration/EditCurationPage";
import EditPlacePage from "./pages/editplace/EditPlacePage";
import LoginPage from "./pages/auth/LoginPage";
import HomePage from "./pages/home/HomePage";
import LoginRedirectPage from "./pages/auth/LoginRedirectPage";
import CompleteProfilePage from "./pages/auth/CompleteProfilePage";
import InviteUserPage from "./pages/invite/InviteUserPage";
import InputCommentPage from "./pages/addcomment/InputCommentPage";
import PrivacyPage from "./pages/legal/PrivacyPage";
import TermsOfServicePage from "./pages/legal/TermsOfServicePage";
import WIP from "./pages/wip/WIP";
import MembersPage from "./pages/member/MembersPage";
import ProfilePage from "./pages/profile/ProfilePage";
import OwnProfilePage from "./pages/profile/OwnProfilePage";
import EditProfilePage from "./pages/profile/EditProfilePage";
import ImportFromGoogleMapsTutorial from "./pages/tutorial/ImportFromGoogleMaps";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import AppInviteCodePage from "./pages/appinvite/AppInviteCodePage";
import CurationMapPage from "./pages/curation/CurationMapPage";
import AddCurationSuccessPage from "./pages/addcuration/AddCurationSuccessPage";
import RemoveAccountPage from "./pages/removeaccount/RemoveAccountPage";
import AcceptInvitationPage from "./pages/invitation/AcceptInvitationPage";
import SupportTicketPage from "./pages/supportticket/SupportTicketPage";

export default function App() {

  // TODO: Add SDKs for Firebase products that you want to use
  // https://firebase.google.com/docs/web/setup#available-libraries
  
  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
    apiKey: "AIzaSyB9mRRmpNVfuWdQmgICuSU2QsVlnMuFOAg",
    authDomain: "roambear-web.firebaseapp.com",
    projectId: "roambear-web",
    storageBucket: "roambear-web.appspot.com",
    messagingSenderId: "961653013880",
    appId: "1:961653013880:web:43869ab280d2bcd8bddc8d",
    measurementId: "G-GFFWBVZYPG"
  };
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  return <BrowserRouter>
    <Routes>
      <Route path="/" element={<HomePage analytics={analytics} tab={"yours"}/>} />
      <Route path="/code" element={<AppInviteCodePage analytics={analytics}/>} />
      {/* <Route path="/" element={<HomePage />} /> */}
      <Route path="/explore" element={<HomePage />} />
      <Route path="/yours" element={<HomePage analytics={analytics} tab={"yours"}/>} />
      <Route path="/privacy" element={<PrivacyPage />} />
      <Route path="/terms" element={<TermsOfServicePage />} />
      <Route path="/login" element={<LoginPage analytics={analytics} />} />
      <Route path="/login/redirect" element={<LoginRedirectPage />} />
      <Route path="/signup/completion" element={<CompleteProfilePage analytics={analytics} />} />
      <Route path="/collections/:curationId" element={<CurationPage analytics={analytics} />} />
      <Route path="/collections/:curationId/map" element={<CurationMapPage analytics={analytics} />} />
      <Route path="/places/:id" element={<PlaceDetailsPage analytics={analytics} />} />
      <Route path="/places/:placeId/edit" element={<EditPlacePage analytics={analytics} />} />
      <Route path="/places/:placeId/comment" element={<InputCommentPage />} />
      <Route path="/collections/:curationId/add" element={<AddPlacePage analytics={analytics} />} />
      <Route path="/collections/:curationId/edit" element={<EditCurationPage analytics={analytics} />} />
      <Route path="/collections/:curationId/invite" element={<InviteUserPage />} />
      <Route path="/collections/:curationId/members" element={<MembersPage analytics={analytics} />} />
      <Route path="/collections/add" element={<AddCurationPage analytics={analytics} />} />
      <Route path="/collections/:curationId/success" element={<AddCurationSuccessPage analytics={analytics} />} />
      <Route path="/profile/edit" element={<EditProfilePage analytics={analytics} />} />
      <Route path="/profile/:userId" element={<ProfilePage analytics={analytics} />} />
      <Route path="/profile" element={<OwnProfilePage analytics={ analytics } />} />
      <Route path="/tutorial/googlemaps" element={<ImportFromGoogleMapsTutorial analytics={analytics}/>} />
      <Route path="/profile/delete" element={<RemoveAccountPage analytics={analytics}/>} />
      <Route path="/invitations/:invitationLinkId" element={<AcceptInvitationPage analytics={analytics}/>} />
      <Route path="/support/question" element={<SupportTicketPage analytics={analytics}/>} />
    </Routes>
  </BrowserRouter>
}