import Lottie from "lottie-react"
import lottieSuccess from '../../shared/lottie/lottie-success.json';
import CoorayAppBar, { NavigationButtonType } from "../../shared/components/CoorayAppBar";
import { useNavigate } from "react-router-dom";

export default function SupportTicketSuccessPage({}) {
    const navigate = useNavigate()
    function onBackPressed() {
        navigate(`/`, { replace: true })
    }
    return (
        <div>
            <CoorayAppBar title={`Thank you for your question`} onBackPressed={onBackPressed} navigationType={NavigationButtonType.Close}/>
            <div className="flex flex-col w-full items-center place-content-center">
                <Lottie className="w-36 h-36" animationData={lottieSuccess} loop={true} />
                <div className="font-normal text-base text-base-content text-center pl-4 pr-4"><div>Our team will reply to you via email within 5 working days</div></div>
                <button className="btn btn-active btn-primary mt-4" onClick={() => { onBackPressed() } }>Close</button>
            </div>
        </div>
    )
}
